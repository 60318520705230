.chat-line {
    &:hover {
        .content .time {
            visibility: visible;
        }
    }
    &.user .profilepic {
        
        img {
            width: 45px;
            padding: 0 2px;
        }
    }
    .profilepic {
        margin: 0;
        padding: 0;
        img {
            width: 45px;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        height: auto;
        max-width: 90%;
        margin-left: 10px;
        text-align: start;
        .text {
            overflow-wrap: break-word;
            word-break: break-word;
            white-space: pre-wrap;
            background: #fdfdfe;
            border-radius: 5px;
            font-size: 15px;
            min-height: 50px;
            line-height: 28px;
            color: #262737;
            padding: 10px 15px;
            margin-top: 3px;
            .audio {
                margin-left: 8px;
                font-size: 20px;
                cursor: pointer;
            }
        }
        .time {
            visibility: hidden;
            font-size: 12px;
            color: #666;
            display: inline-block;
            white-space: nowrap;
            line-height: 1em;
        }
        .loading-wrap {
            display: flex;
            height: 100%;
        }
        .icon {
            margin-right: 10px;
            vertical-align: text-top;
        }
    }
    &.failed {
        .content {
            .text {
                color: #ef4444;
                svg {
                    margin-top: 2px;
                }
            }
        }
    }
}
.chat-line.user {
    //   flex-direction: row-reverse;
    //   text-align: end;
    .content {
        // align-items: flex-end;
        .text {
            //   color: #333;
            //   background-color: #b3ebdf;
            background-color: transparent;
            padding: 0;
            min-height: 30px;
        }
    }
}
.chat-line.bot {
    margin-bottom: 16px;
}

@media (max-width: 700px) {
    .chat-line {
        .content {
            max-width: 90%;
            margin-left: 0;
        }

        &.user .profilepic {
        
            img {
                width: 35px;
                padding: 0 2px;
            }
        }
        .profilepic {
            margin: 0;
            padding: 0;
            img {
                width: 35px;
            }
        }
    }
}