.sidebar {
    display: flex;
    width: 270px;
    height: 100%;
    font-size: 14px;
    overflow: hidden;
    transition: all 0.2s ease;
    border-right: 1px solid #e5e7eb;

    &-collapse {
        width: 50px;
    }
    &-action {
        width: 50px;
        flex: 0 0 50px;
        height: 100%;
        text-align: center;
        background-color: #e5e7eb;
        position: relative;

        .logo {
            margin: 5px 0 10px;
            border-radius: 0px;
            img {
                width: 30px;
            }
        }

        .link {
            display: inline-block;
            width: 40px;
            height: 40px;
            font-size: 24px;
            color: #555;
            background-color: transparent;
            margin-top: 15px;
            border-radius: 5px;

            svg,img {
                vertical-align: text-top;
                margin-top: 2px;
            }
            &.active {
                background-color: #f3f4f6;
            }
            &:hover {
                background-color: #f3f4f6;
            }
        }

        .toggle-sidebar {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-bottom: 0px;

            button {
                color: #333;
                width: 100%;
                font-size: 16px;
                margin-top: 0;
                height: 60px;
                border: 0;
                border-radius: 0;
                background-color: #e5e7eb;
                &:hover {
                    background-color: #f3f4f6;
                }
            }
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        min-width: 210px;
        background-color: #f3f4f6;
        padding: 15px;
        overflow: auto;
    }
}
