.loading-wrap {
    display: flex;
    justify-content: center;
}
.msg-loading {
  width: 30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.msg-loading div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #333;
  animation: loadingFade 0.5s ease-in-out alternate infinite;
}

.msg-loading div:nth-of-type(1) {
  animation-delay: -0.2s;
}

.msg-loading div:nth-of-type(2) {
  animation-delay: -0.1s;
}

@keyframes loadingFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}