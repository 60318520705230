.theme-basic {
    height: 100%;
    display: flex;
    &-sidebar {
        width: auto;
    }
    &-main {
        height: 100%;
        width: 100%;
        .theme-basic-header {
            height: 50px;
        }
        .theme-basic-content {
            height: calc(100% - 50px);
        }
    }
}