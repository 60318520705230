.app-header {
    height: 100%;
    border-bottom: 1px solid #e5e7eb;
    box-shadow: 0px 0px 5px 1px rgba(131, 131, 131, 0.1);

    .row {
        height: 100%;
        align-items: center;
    }

    .title {
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        margin-right: 100px;
        &.short {
            display: none;
        }
    }

    .profile {
        color: #353535;
        font-size: 22px;
        width: 35px;
        height: 35px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        svg {
            vertical-align: baseline;
        }
        &:hover {
            background-color: #414755;
        }
    }

    .logo {
        width: 160px;
        margin-left: 20px;
    }

    .right {
        margin-right: 10px;
        .autoplay {
            cursor: pointer;
            line-height: 1;
            text-align: center;
            color: #50545a;
            .icon {
                font-size: 18px;
            }
            p {
                font-size: 12px;
                margin: 0;
            }
        }
    }
}

@media (max-width: 450px) {
    .app-header {
        .title {
            &.long {
                display: none;
            }
            &.short {
                display: block;
            }
        }
    }
}
@media (max-width: 700px) {
    .app-header {
        .title {
            margin-right: 0;
        }
    }
}