@mixin arrow-icon {
    background-color: #fff;
    &::after {
        border-top-color: #666;
    }
}

.custom-dropdown {
    z-index: 8;
    display: flex;

    color: #666;

    cursor: pointer;
    user-select: none;

    &.show {
        .arrow-icon {
            &.fill {
                transform: rotate(180deg);

                @include arrow-icon;
            }
        }
        .dropdown-toggle {
            &:hover {
                .arrow-icon {
                    &.fill {
                        @include arrow-icon;
                    }
                }
            }
        }
    }

    .dropdown-icon img {
        width: 15px;
        margin-top: 1px;
        margin-right: 5px;

        vertical-align: text-top;
    }

    .arrow-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;

        margin-left: 3px;

        border-radius: 50%;
        vertical-align: inherit;

        &.fill {
            &::after {
                display: inline-block;

                border-top: 4px solid #00a680;
                border-right: 4px solid transparent;
                border-bottom: 0;
                border-left: 4px solid transparent;

                content: "";
            }
        }

        &.line {
            &::after {
                display: inline-block;
                width: 6px;
                height: 6px;
                margin-top: 4px;

                border: 1px solid #a1a1a1;
                border-top: 0;
                border-left: 0;
                transform: rotate(45deg);

                content: "";
            }
        }
    }

    .dropdown-toggle {
        padding: 0;

        border: none;
        box-shadow: none;
        &::after {
            display: none;
        }
        &:hover {
            color: #333;
            .arrow-icon {
                &.fill {
                    // background-color: #d1d1d1;
                    &::after {
                        border-top-color: #666;
                    }
                }

                &.line {
                    &::after {
                        border-color: #666;
                    }
                }
            }
        }
    }

    .dropdown-menu {
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
        margin-top: 3px !important;
        min-width: 50px;
        border: 1px solid #ddd;

        border: none;
        cursor: default;

        .dropdown-item {
            display: flex;
            align-items: center;
            margin-bottom: 1px;
            padding: 8px 15px;

            color: #000;
            font-size: 14px;
            .item-icon {
                line-height: 18px;
                img, svg {
                    font-size: 16px;
                    width: 16px;
                    margin-right: 7px;
                }
            }
            &.active,
            &:hover {
                background-color: #efefef;
            }
        }
    }

    &.right {
        .dropdown-menu {
            right: 0 !important;
            left: unset !important;
        }
    }
}