.config-bar {
    outline: none;
    gap: 20px;
    font-size: 14px;
    margin: 0;

    .wrapper {
        display: flex;
        justify-content: flex-end;

        .custom-dropdown {
            margin-left: 15px;
        }
    }
}
