$button-color: #00a680;
$button-hover-color: #009e79;

body {
  color: #333;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 1px;
  height: 100vh;
  width: 100%;
  position: fixed;
}

#root {
  height: 100vh;
  background: linear-gradient(to right, #e9edf7, #e9eef9 50%, #eef0f6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select {
  color: #333;
  border: 1px solid #aaa;
  font-size: 14px;
  padding: 4px 0 4px 10px;
  border-radius: 5px;
  cursor: pointer;;
}

::-webkit-scrollbar {
  display: none;
  width: 4px;
  height: 4px;

  background-color: transparent;

  &-thumb {
    background-color: #ccc;
    border-radius: 4px;
    box-shadow: unset;
  }

  &-track {
    background-color: transparent;
  }
}

/* reset bootstrap start */
.App {
  .container-fluid {
    padding: 0;
  }

  .btn {
    border: none;
    font-size: 14px;
    &.btn-primary {
      color: #fff;
      background-color: $button-color;
      &:hover, &:active {
        background-color: $button-hover-color;
      }
    }

    &.btn-danger {
      color: #dc2726;
      background-color: #fee2e2;
      &:hover, &:active {
        color: #dc2726;
        background-color: #ffe7e7;
      }
    }
    &.btn-link {
        background-color: transparent;
        color: #333;
        &:hover, &:active {
            background-color: transparent;
        }
    }
  }
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }

  .form-switch {
    cursor: pointer;
    user-select: none;
    .form-check-input, .form-check-label {
      cursor: pointer;
      color: #333;
    }
    .form-check-input {
      &:checked {
        border: none;
        background-color: $button-color;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  .card {
    border-color: #e5e7eb;
    &-header {
      font-size: 18px;
      font-weight: 500;
      border-color: #e5e7eb;
    }
    &-title {
      font-size: 16px;
    }
  }
}
/* reset bootstrap end */


/* common style start */
body .App {
  .icon-btn.btn-primary {
    font-size: 18px;
    color: $button-color;
    background-color: transparent;
    &:hover, &:active {
      color: $button-color;
      background-color: #f3f4f6;
      box-shadow: 1px 1px 2px 0 rgba(190, 190, 190, 0.5);
    }
  }
}

.loading {
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    border: 1px solid #000;
    border-radius: 50%;
    border-top: none;
    background: transparent;
    -webkit-animation: xloading 1s steps(12, end) infinite;
    animation: xloading 1s steps(12, end) infinite;
}

@-webkit-keyframes xloading {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}
@keyframes xloading {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}
/* global style end */
