.chat-view {
    height: 100%;
    overflow-y: auto;
    .response-window {
        max-width: 1500px;
        margin: 0 auto;
        padding: 20px 70px 10px;
        min-height: calc(100% - 150px);
    }
    .footer {
        bottom: 0;
        width: 100%;
        height: 150px;
        position: sticky;
        padding: 5px 30px 20px;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.8);
        &-wrapper {
            max-width: 1500px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 700px) {
    .chat-view {
        .response-window {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
@media (max-width: 450px) {
    .chat-view {
        .footer {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}