@mixin position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

$mic-wave-size: 60px;

.input-box {
  width: 100%;
  outline: none;
  display: flex;
  padding: 5px 0 10px;
  align-items: center;
  .mic-wrapper {
    position: relative;
    height: $mic-wave-size;
    width: $mic-wave-size;
    .mic-btn {
        @include position-center;
        z-index: 9;
        margin-top: -3px;
        font-size: 24px !important;
        background-color: transparent !important;
        border-radius: 50%;
        &:hover {
            box-shadow: none !important;
        }
    }

    .mic-bg {
        @include position-center;
        background-color: #fff;
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .mic-wave {
        visibility: hidden;
        position: relative;
        height: $mic-wave-size;
        width: $mic-wave-size;
        margin-top: -5px;
        &.active {
            visibility: visible;
        }
    }
    .mic-wave:after, .mic-wave:before {
        @include position-center;
        content: "";
        border: 4px solid rgb(0, 166, 128, 0.4);
        border-radius: 50%;
        animation: mic-wave 1.2s cubic-bezier(0,.2,.8,1) infinite
    }
    
    .mic-wave:after {
        animation-delay: -.4s
    }
  }

  .input-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: auto;
    padding: 4px 8px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    box-shadow: 
      0px 1px 3px 0px rgba(0, 0, 0, 0.1), 
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    textarea {
      align-self: center;
      width: 100%;
      height: 40px;
      font-size: 16px;
      border: 0;
      margin: 0;
      padding: 0.5em;
      line-height: 1.5em;
      resize: none;
      overflow: hidden;
    }
    button {
      font-size: 24px;
      user-select: none;
    }
  }
}

@keyframes mic-wave {
    0% {
        width: 30%;
        height: 30%;
        opacity: 1
    }

    to {
        width: 90%;
        height: 90%;
        opacity: 0
    }
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}